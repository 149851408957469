@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "FSIndustrie";
  src: url(../fonts/Rubik-VariableFont_wght.ttf) format("truetype-variations");
  font-weight: 300 700;
  font-style: normal;
}

@font-face {
  font-family: "JetBrainsMono";
  src: url(../fonts/FiraCode-VariableFont_wght.woff2) format("woff2-variations");
  font-weight: 300 700;
  font-style: normal;
}

body {
  @apply dark:bg-zinc-900 dark:text-zinc-100 dark:bg-gradient-to-b dark:from-zinc-900 dark:to-zinc-800 dark:from-30% antialiased font-sans;
}
